<template>
  <div class="flex_col box m_tb_20 pointer">
    <div style="height: 160px;" @click="toDetails(resourceInfo.id)">
      <img :src="resourceInfo.coverUrl?imgToRespomse(resourceInfo.coverUrl):typesJSON[resourceInfo.types]"
           height="100%"
           :alt="resourceInfo.name">
    </div>
    <div class="p_in_10">
      <p class="fs_16 text_hidden">{{ resourceInfo.name }}</p>
    </div>
    <el-tag class="tag" size="small" :type="colorJSON[resourceInfo.type]">{{ typeJSON[resourceInfo.type] }}</el-tag>
  </div>
</template>
<script>
import word from 'assets/image/word.png'
import pdf from "assets/image/pdf.png";
import ppt from "assets/image/ppt.png";
import excel from "assets/image/excel.png";
import video from "assets/image/video.png";
import voice from "assets/image/voice.png";
import {imgToRespomse} from '@/utils/imgToResponse'

export default {
  name: 'RegionResource',
  props: {
    resourceInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    scene: {
      type: String,
      default: ''
    }
  },
  data: () => {
    return {
      typeJSON: {
        // 文档
        1: '文档',
        // 音频
        2: '音频',
        // 视频
        3: '视频',
        // 图片
        4: '图片'
      },
      colorJSON: {
        // 文档
        1: 'success',
        // 音频
        2: 'warning',
        // 视频
        3: 'danger',
        // 图片
        4: 'info'
      },
      typesJSON: {
        doc: word,
        pdf: pdf,
        pptx: ppt,
        xlsx: excel,
        mp4: video,
        m3u8: video,
        mp3: voice
      }
    }
  },
  methods: {
    imgToRespomse(url) {
      return imgToRespomse(url)
    },
    toDetails(id) {
      if (this.scene === 'school') return this.$router.push({
        path: '/resource/details',
        query: {scene: this.scene, id}
      })
      this.$router.push({path: '/resource/details', query: {id}})
    }
  }
}
</script>
<style scoped>
.box {
  width: 27rem;
  position: relative;
  border-radius: .5rem;
  transition: all 200ms;
  margin-right: 4rem;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, .1);
}

.box:hover {
  box-shadow: 0 11px 24px 0 rgb(0 0 0 / 20%);
  transition: all 200ms;
}

.box img {
  width: 100%;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
}

.box:nth-child(4n) {
  margin-right: 0;
}

.tag {
  position: absolute;
  top: 1rem;
  left: -.5rem;
}
</style>
