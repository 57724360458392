export default {
  baseUrl: 'https://www.wuhousmartedu.com',
  /* 为图片地址转换定义的变量
   *2022-07-20*
   */
  env: "development",
  base: {
    development: "http://172.16.10.233:7071",
    production: "https://www.wuhousmartedu.com"
  }
}
