<template>
  <el-container class="container">
    <!-- <el-header class="l_h_60">
       <el-row>
         <el-col :span="6">
           <div class="logo grid-content">区域资源库</div>
         </el-col>
         <el-col :span="12">
           <div class="search grid-content">
             <el-input placeholder="请输入内容" v-model="searchValue" class="input-with-select">
               <el-select v-model="searchType" slot="prepend" placeholder="请选择">
                 <el-option :label="item.name" :value="item.id" v-for="(item,index) in contents" :key="index">
                 </el-option>
               </el-select>
               <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
             </el-input>
           </div>
         </el-col>
         <el-col :span="6">
           <div class="grid-content"></div>
         </el-col>
       </el-row>
     </el-header> -->
    <el-container class="main-container">
      <el-main>
        <div class="boxs fs_16 bg_fff p_in_10">
          <div class="row m_b_10">
            <div class="type">
              分类：
            </div>
            <div class="col" v-for="(item,index) in contents" :key="index" @click="selectType(item)">
              <el-badge class="item">
                <el-button size="small" :type="item.isActive==true?'primary':'normal'">{{ item.name }}</el-button>
              </el-badge>
            </div>
          </div>
          <!-- 二级分类-->
          <div class="row m_b_10" v-if="subClassify.length>0">
            <div class="type" style="width: 50px;">
            </div>
            <div class="col" v-for="(item,index) in subClassify" :key="index" @click="selectSubType(item)">
              <el-badge class="item">
                <el-button size="small" :type="item.isActive==true?'primary':'normal'">{{ item.name }}</el-button>
              </el-badge>
            </div>
          </div>
          <!-- 剩余分类-->
          <div class="row m_b_10" v-for="(classify,classifyIndex) in otherClassify" :key="classifyIndex">
            <div class="type" style="width: 50px;">
            </div>
            <div class="col" v-for="(item,index) in classify" :key="index" @click="selectOtherType(item,classifyIndex)">
              <el-badge class="item">
                <el-button size="small" :type="item.isActive==true?'primary':'normal'">{{ item.name }}</el-button>
              </el-badge>
            </div>
          </div>
          <div class="row">
            <div class="type">
              标签：
            </div>
            <div class="col" v-for="(item,index) in lists" :key="index" @click="selectClassify(item)">
              <el-badge class="item">
                <el-button size="small" :type="item.isActive==true?'primary':'normal'">{{ item.name }}</el-button>
              </el-badge>
            </div>
          </div>
          <el-divider></el-divider>
          <div class="row">
            <div class="type">
              排序：
            </div>
            <div class="col" v-for="(item,index) in sort" :key="index" @click="selectSort(item)">
              <el-badge class="item">
                <el-button size="small" :type="item.isActive==true?'primary':'normal'">{{ item.name }}</el-button>
              </el-badge>
            </div>
          </div>
          <div class="row">
            <div class="type">
              类型：
            </div>
            <div class="col" v-for="(item,index) in types" :key="index" @click="selectResourceType(item)">
              <el-badge class="item">
                <el-button size="small" :type="item.isActive==true?'primary':'normal'">{{ item.dictValue }}</el-button>
              </el-badge>
            </div>
          </div>
        </div>
        <!-- <FilterItems ref="children" @contents="getContents" :queryListData="datas" :showLoading="showLoading" @resourceList="getResourceList" class="m_t_15"></FilterItems> -->

        <div class="list m_t_15" v-loading="loading">
          <!-- <el-row :gutter="10">
             <el-col :span="6" v-for="(item,index) in source.records" :key="index">
               <div class="card">
                 <div class="img">
                   <img :src="item.coverUrl">
                 </div>
                 <div class="title p_in_10">{{item.name}}</div>
               </div>
             </el-col>
           </el-row> -->
          <div class="flex_row_wrap">
            <Resource class="card" v-for="data of source.records" :key="data.id" :resourceInfo="data"/>
          </div>
          <el-empty description="暂无数据" v-if="source.records" v-show="!source.records.length"></el-empty>
        </div>
        <!--  分页  -->
        <div class="pager">
          <el-pagination background
                         v-if="source.records"
                         v-show="source.records.length>0"
                         layout="prev, pager, next"
                         :total="source.total"
                         :page-size="source.size"
                         :current-page="source.current"
                         v-on:current-change="currentChange_click"
                         hide-on-single-page>
          </el-pagination>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import Resource from "@/views/resourceCenter/components/region/RegionRescource";
// import FilterItems from "@/components/regional/FilterItems";
import {
  getContents_api,
  getSubClassifyById_api,
  getRegionIndexPage_api,
  getList_api,
  dictionary_api
} from "@/api/resourceCenter/region/search"

export default {
  // components: {
  //   FilterItems
  // },
  components: {
    Resource
  },
  data() {
    return {
      searchValue: "",
      searchType: "",
      datas: [],
      source: [],
      loading: false,
      contents: [], //分类列表
      subClassify: [], //子目录
      otherClassify: [], //其他目录
      lists: [], //标签列表
      types: [],//分类标签
      sort: [{
        "name": "全部",
        "id": "",
      }, {
        "name": "浏览最多",
        "id": "1",
      }, {
        "name": "点赞最多",
        "id": "2"
      }, {
        "name": "收藏最多",
        "id": "3"
      }], //排序
      parms: {} //请求数据
    }
  },
  watch: {
    $route() {
      this.search();
    }
  },
  mounted() {
    // this.searchValue = this.$route.query.keyword;
    this.init();
  },
  methods: {
    async init() {
      //获取分类
      let type = await dictionary_api({
        code: 'resources_type'
      });
      type.data.unshift({
        "dictValue": '全部',
        "isActive": true
      });
      this.types = type.data;
      let contents = await getContents_api();
      contents.data.unshift({
        "name": '全部',
        "id": '1'
      });
      this.contents = contents.data;
      let lists = await getList_api();
      lists.data.unshift({
        "name": '全部',
        "isActive": true
      });
      this.lists = lists.data;
      this.loading = false;
      // let regionIndexPage = await getRegionIndexPage_api(this.params);
      //默认显示第一项
      //this.selectType(this.contents[0]);
      this.search();
    },
    //点击分类
    selectType(r) {
      //取消上一次选择的选项
      let lastSelect = this.contents.find(item => item.isActive == true);
      if (typeof (lastSelect) != 'undefined' && lastSelect.id != r.id) {
        lastSelect.isActive = false;
      }
      //默认子集
      this.otherClassify = [];
      //恢复默认排序
      this.sort.map((val, index) => {
        val.isActive = index == 0 ? true : false;
      });
      //恢复默认标签
      this.lists.map((val, index) => {
        val.isActive = index == 0 ? true : false;
      });
      //选中当前选项
      if (!r.isActive) {
        this.parms = {
          size: 12,
          current: 1
        };
        r.isActive = true;
        this.loading = true;
        this.parms.classifyId = r.id == 1 ? '' : r.id;
        this.getResourceList();
        this.searchType = r.id;
      }
      //根据分类获取子目录
      this.getSubClassifyById({
        "id": r.id,
        "type": 1
      });
    },
    //点击一类子集
    selectSubType(r) {
      let lastSelect = this.subClassify.find(item => item.isActive == true);
      if (typeof (lastSelect) != 'undefined' && lastSelect.id != r.id) {
        lastSelect.isActive = false;
      }
      if (!r.isActive) {
        r.isActive = true;
        this.loading = true;
        this.parms.classifyId = '';
        this.parms.contentsId = r.id;
        this.getResourceList();
        //请求下级集合
        this.otherClassify = [];
        getSubClassifyById_api({
          "id": r.id,
          "type": 2
        }).then(r => {
          this.otherClassify.push(r.data);
        })
      }
    },
    //递归剩余子集
    // getOthers(id){
    //    getSubClassifyById_api({"id":id,"type":2}).then(r=>{
    //      if()
    //    });
    // },
    //获取目录数据
    async getClassifyById2() {
      await getSubClassifyById_api
    },
    //点击排序
    selectSort(r) {
      //取消上一次选择的选项
      let lastSelect = this.sort.find(item => item.isActive == true);
      if (typeof (lastSelect) != 'undefined' && lastSelect.id != r.id) {
        lastSelect.isActive = false;
      }
      //选中当前选项
      if (!r.isActive) {
        r.isActive = true;
        this.parms.sortDiv = r.id;
        this.getResourceList();
      }
    },
    //点击标签
    selectClassify(r) {
      let lastSelect = this.lists.find(item => item.isActive == true);
      if (typeof (lastSelect) != 'undefined' && lastSelect.id != r.id) {
        lastSelect.isActive = false;
      }
      if (!r.isActive) {
        r.isActive = true;
        this.parms.label = r.name == '全部' ? '' : r.name;
        this.parms.current = 1;
        this.getResourceList();
      }
    },
    //点击二级以下标签
    selectOtherType(r, index) {
      let lastSelect = this.otherClassify[index].find(item => item.isActive == true);
      if (typeof (lastSelect) != 'undefined' && lastSelect.id != r.id) {
        lastSelect.isActive = false;
      }
      if (!r.isActive) {
        r.isActive = true;
        this.parms.classifyId = '';
        this.parms.contentsId = r.id;
        this.getResourceList();
        //请求下级集合
        getSubClassifyById_api({
          "id": r.id,
          "type": 2
        }).then(r => {
          if (r.data.length > 0) {
            //删除点击后面的数组
            this.otherClassify.splice(index + 1, this.otherClassify.length - index);
            this.otherClassify.push(r.data);
          }

        })
      }
    },
    //资源数据
    async getResourceList() {
      this.loading = true;
      let resourceLists = await getRegionIndexPage_api(this.parms);
      resourceLists.data.records = this.processingData(resourceLists.data.records);
      this.source = resourceLists.data;
      this.loading = false;
    },
    //根据目录id或取子目录
    async getSubClassifyById(obj) {
      let subClassify = await getSubClassifyById_api(obj);
      this.subClassify = subClassify.data;
    },
    search() {
      let query = this.$route.query;
      this.selectType(this.contents.find(item => item.id == query.id));
      this.parms.name = query.keyword;
      if (typeof (query.sortDiv) != 'undefined') {
        this.selectSort(this.sort.find(item => item.id == query.sortDiv));
        return false;
      }
      this.getResourceList();
    },
    currentChange_click(current) {
      this.parms.current = current;
      this.getResourceList();
    },
    selectResourceType(r) {
      let lastSelect = this.types.find(item => item.isActive == true);
      if (typeof (lastSelect) != 'undefined' && lastSelect.id != r.id) {
        lastSelect.isActive = false;
      }
      if (!r.isActive) {
        r.isActive = true;
        this.parms.type = r.dictValue == '全部' ? '' : r.dictKey;
        this.parms.current = 1;
        this.getResourceList();
      }
    },
    // 处理获取的数据
    processingData(resourceList) {
      resourceList.forEach(data => {
        data.teacherInfo = data.introduce.split('</br>')
      })
      return resourceList
    }
  }
}
</script>

<style scoped>
.main-container {
  background-color: #f6faff;
}

.l_h_60 {
  line-height: 60px;
}

.el-select {
  width: 130px;
}

.logo {
  width: 100px;
  margin-left: 200px;
  font-weight: 400;
  font-size: 1.5rem;
}

.list {
  display: block;
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.card {
  /* cursor: pointer; */
  margin-bottom: 4rem;
  box-sizing: border-box;


}

/* .card .img {
  height: 160px;
  transition: all .5s;
} */

/* .card img {
  border-top-left-radius: .8rem;
  border-top-right-radius: .8rem;
  object-fit: cover;
  height: 100%;
  width: 100%;
} */

/* .card .title {
  background-color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;

} */

/* .card:hover {
  box-shadow: 0 0 1rem .2rem #e0e0e0;
  transform: translateY(-.8rem);
  transition: all .5s;
}
*/
.pager {
  width: 1200px;
  margin: 0 auto;
  /* text-align: center; */
}

.boxs {
  width: 1200px;
  margin: 0 auto;
}

.boxs .row {
  display: flex;
  flex-wrap: wrap;
  /*  width: 1000px;
  */
  /* border: 1px #000 solid; */
}

.type:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.boxs .type {
  font-size: 1.5rem;
  margin-right: 1rem;
}

.boxs .col {
  /* width: 10%; */
  margin-right: 2.2rem;
  margin-bottom: 1rem;
}

.el-divider {
  margin: 14px 0;
}
</style>
