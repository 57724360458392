/* 图片回显处理 */
import img from "@/assets/image/default.png"
import api from "@/api/api"
export const icon=img;
let baseUrl=api.base;
let env=api.env;
 let prefix=(env=='development'?baseUrl[env]+'/regionIndex/imgToResponse?urlStr=':baseUrl[env]+'/api/regionIndex/imgToResponse?urlStr=')
export function imgToRespomse(img) {
  let newImg='';
  if(img){
    if(img.split(":")[0]=='http'){
      newImg= prefix+img
    }else{
      newImg=img
    }
  
  }else{
    newImg=icon;
  }
  return newImg;
}
